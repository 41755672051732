<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-user-check small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Surat Penugasan
            </h4>
          </div>

          <!-- Table of letterOfAssignment-->
          <div>
            <vue-good-table
              mode="remote"
              :is-loading.sync="isLoading"
              :total-rows="totalRecords"
              :columns="columns"
              :rows="rows"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'name', type: 'asc' },
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                nextLabel: 'selanjutnya',
                prevLabel: 'sebelumnya',
                rowsPerPageLabel: 'Data per halaman',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <b-form-datepicker
                  v-if="column.field == 'date'"
                  size="sm"
                  style="z-index: 9999"
                  placeholder="Tanggal"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="id"
                  @input="(value) => updateFilters(column, value)"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Status -->
                <span
                  v-if="props.column.field === 'assign_name'"
                  class="justify-content-start"
                >
                  <div>
                    <b-badge
                      v-for="(item, index) in props.row
                        .letter_of_assignment_participants"
                      :key="index"
                      :variant="item.user_id == user.id ? 'primary' : 'secondary'"
                      class="small-gap-right cursor-pointer"
                      @click="
                        $router.push({
                          name: 'users.edit',
                          params: { id: item.user_id },
                        })
                      "
                    >
                      {{ item.user.name }}
                    </b-badge>
                  </div>
                </span>

                <span
                  v-if="props.column.field === 'is_public'"
                  class="d-flex justify-content-start"
                >
                  <b-badge
                    :variant="props.row.is_public | statusClass"
                    class="small-gap-right"
                  >
                    {{ props.row.is_public ? "publik" : "private" }}
                  </b-badge>
                </span>

                <a
                  v-else-if="props.column.field === 'file'"
                  :href="props.row.file"
                  target="__blank"
                  class="d-flex justify-content-start"
                >
                  <b-badge
                    variant="danger"
                    class="small-gap-right cursor-pointer"
                  >
                    Buka
                  </b-badge>
                </a>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-start"
                >
                  <router-link
                    class="btn btn-sm btn-danger"
                    :to="{name: checkAuthorizeRole('letter-of-assignments.show'), params: {id: props.row.id}}"
                  >
                    <i class="ti ti-search" />
                  </router-link>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { getUserData, checkAuthorizeRole } from '@/auth/utils'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormRadio,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pascalCase, avatarText } from '@core/utils/filter'
import {
  reqGetLetterOfAssignments,
  reqDeleteLetterOfAssignment,
} from '@/api/admin/letterOfAssignment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BFormCheckbox,
    BFormDatepicker,

    VueGoodTable,
    vSelect,
  },
  filters: {
    statusClass(status) {
      if (status) {
        return 'success'
      }
      return 'danger'
    },
  },
  props: {},
  data() {
    return {
      // Filter
      user: getUserData(),
      isLoading: true,
      statuses: ['Aktif', 'Tidak Aktif', 'Diblokir'],

      // Table option
      data: {},
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },

        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: 'NO Agenda',
          field: 'number',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nomor agenda', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Nama Agenda',
          field: 'name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama agenda', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Ditujukan Untuk',
          field: 'assign_name',
          width: '30%',
          sortable: false,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari Staff', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Surat',
          field: 'file',
          width: '5%',
          sortable: false,
          filterOptions: {
            enabled: false, // enable filter for this column
          },
        },
        {
          label: 'Tanggal',
          field: 'date',
          type: 'date',
          tdClass: 'text-left',
          thClass: 'text-left',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss', // expects 2018-03-16
          dateOutputFormat: 'dd MMM yyyy', // outputs Mar 16th 2018
          width: '130px',
          filterOptions: {
            customFilter: true,
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'tahun/bulan/tanggal', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.dateFilter, // custom filter function that
            trigger: 'change', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Tipe',
          field: 'is_public',
          width: '120px',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Semua', // placeholder for filter input
            filterDropdownItems: [
              { value: 1, text: 'Publik' },
              { value: 0, text: 'Private' },
            ], // dropdown (with selected values) instead of text input
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },

        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    rows() {
      if (this.data.data) {
        return this.data.data
      }
      return []
    },
    totalRecords() {
      if (this.data.data) {
        return this.data.total
      }
      return 0
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      reqGetLetterOfAssignments(this.serverParams).then(response => {
        this.data = response.data.data
        this.isLoading = false
      })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    dateFilter(data, filterString) {
      console.log(data, filterString)
    },
    handleEdit(row) {
      this.$router.push({
        name: 'superadmin.letter-of-assignments.edit',
        params: {
          id: row.id,
        },
      })
    },
    handleDelete(row) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan bisa mengembalikan data ini lagi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteLetterOfAssignment(row.id)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil',
                  icon: 'Check',
                  message: response.data.message || 'Berhasil menghapus data',
                  variant: 'success',
                },
              })
              this.loadItems()
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'Check',
                  message:
                    error.response.data.message || 'Gagal menghapus data',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    avatarText,
    pascalCase,
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}

.school-filter {
  min-width: 180px !important;
}

.vgt-responsive {
  min-height: 50vh;
}
</style>
